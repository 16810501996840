.card {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 98%;
        background-color: black !important;
        color: white !important;
        border-radius: 10px !important;
        margin: 2%;
        box-shadow: 0px 0px 4px 3px #1f1e1e !important;

        p {
                height: 20vh;
                overflow: auto;
        }
}

.wrapperCard {
        cursor: pointer;
}

.cinemaLogo {
        width: 50px;
        height: 50px;
}

.onlyVac {
        color: blue;
}

.info-container {
        z-index: 2;
        background-color: rgb(0 0 0 / 40%);
        display: block;
}

.slider {
        z-index: 2;
        background-color: rgb(0 0 0 / 40%);
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        display: flex;
        width: 100%;
        height: 50%;
        flex-direction: row;
        justify-content: center;
}

.slide-in-blurred-bottom {
        -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
        animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@media only screen and (min-width: 1000px) {
        .card {
                cursor: pointer;
        }

        .card:hover {
                .info-container {
                        -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
                        animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
                }

                .slider {
                        -webkit-animation: slide-up 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
                        animation: slide-up 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
                }
        }

        .info-container {
                background-color: rgb(0 0 0 / 40%);
                -webkit-animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
                animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both
        }

        .slider {
                -webkit-animation: slide-down 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
                animation: slide-down 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
        }

        .slide-in-blurred-bottom {
                -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
                animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
        }
}

@media only screen and (max-width: 1000px) {
        .slide-out-blurred-bottom {
                -webkit-animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
                animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;

        }

        .slide-up-anim {
                -webkit-animation: slide-up 0.30s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
                animation: slide-up 0.30s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
        }

        .slide-down-anim {
                -webkit-animation: slide-down-mobile 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
                animation: slide-down-mobile 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
        }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-bottom {
        0% {
                -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
                transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
                -webkit-filter: blur(40px);
                filter: blur(40px);
                opacity: 0;
                display: none;
        }

        100% {
                -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                transform: translateY(0) scaleY(1) scaleX(1);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-filter: blur(0);
                filter: blur(0);
                opacity: 1;
                display: block;

        }
}

@keyframes slide-in-blurred-bottom {
        0% {
                -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
                transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
                -webkit-filter: blur(40px);
                filter: blur(40px);
                opacity: 0;
                display: none;
        }

        100% {
                -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                transform: translateY(0) scaleY(1) scaleX(1);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-filter: blur(0);
                filter: blur(0);
                opacity: 1;
                display: block;
        }
}

/**
 * ----------------------------------------
 * animation slide-out-blurred-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-bottom {
        0% {
                -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                transform: translateY(0) scaleY(1) scaleX(1);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-filter: blur(0);
                filter: blur(0);
                opacity: 1;
                display: block;
        }

        100% {
                -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
                transform: translateY(1000px) scaleY(2) scaleX(0.2);
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
                -webkit-filter: blur(40px);
                filter: blur(40px);
                opacity: 0;
                display: none;
        }
}

@keyframes slide-out-blurred-bottom {
        0% {
                -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                transform: translateY(0) scaleY(1) scaleX(1);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-filter: blur(0);
                filter: blur(0);
                opacity: 1;
                display: block;
        }

        100% {
                -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
                transform: translateY(1000px) scaleY(2) scaleX(0.2);
                -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
                -webkit-filter: blur(40px);
                filter: blur(40px);
                opacity: 0;
                display: none;
        }
}

/**
 * ----------------------------------------
 * animation slide-up
 * ----------------------------------------
 */
@-webkit-keyframes slide-up {
        0% {
                -webkit-transform: translateY(0) scaleY(2.5);
                transform: translateY(0) scaleY(2.5);
        }

        100% {
                -webkit-transform: translateY(450px) scaleY(1) scaleX(1);
                transform: translateY(450px) scaleY(1) scaleX(1);
                height: 5%;
        }
}

@keyframes slide-up {
        0% {
                -webkit-transform: translateY(450px) scaleY(2.5);
                transform: translateY(450px) scaleY(2.5);
        }

        100% {
                -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                transform: translateY(0) scaleY(1) scaleX(1);
                height: 5%;
        }
}

/**
 * ----------------------------------------
 * animation slide-down
 * ----------------------------------------
 */
@-webkit-keyframes slide-down {
        0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                height: 5%;

        }

        100% {
                -webkit-transform: translateY(405px);
                transform: translateY(450px);
                height: 50%;

        }
}

@keyframes slide-down {
        0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                height: 5%;

        }

        100% {
                -webkit-transform: translateY(405px);
                transform: translateY(450px);
                height: 50%;

        }
}

/**
 * ----------------------------------------
 * animation slide-down-mobile
 * ----------------------------------------
 */
@-webkit-keyframes slide-down-mobile {
        0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                height: 5%;

        }

        100% {
                -webkit-transform: translateY(200px);
                transform: translateY(200px);
                height: 50%;

        }
}

@keyframes slide-down-mobile {
        0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                height: 5%;

        }

        100% {
                -webkit-transform: translateY(200px);
                transform: translateY(200px);
                height: 50%;

        }
}