@import '../general/variables.scss';
.movie-wrapper{
    margin: 5%;
    display: flex;
    width: 90vw;
    height: 70vh;
    flex-direction: column;
    align-items: center;
}


.column{
    display: flex;
    flex-direction: column;
    color: $secondary-color;
    margin-top: 3%;
    margin-bottom: 3%;
    &-accordion{
        padding-left: 5%;
        width: 95%;
        &-date{
            margin-bottom: 1%;
            color: $secondary-color;
            button{
                margin-left: 5%;
                width: 10%;
                font-size: 60%;
            }
        }
    }
}
.row{
    display: flex;
    color: white;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    &-cinemaslogos{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 1%;
        &-logo{
            border-radius: 10px;
            width: 80px;
            cursor: pointer;
            filter: drop-shadow(2px 4px 6px black);
            &.disabled{
                opacity: 0.5;
            }
        }
    }

}
.accordion-wrapper{
    width: 90%;
    margin-top: 4%;
    &-title{
        color: $secondary-color;

    }
}
.intro{
    display: flex;
    color: $secondary-color;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5%;
    width: 100%;
    .poster{
        width: 40%;
        box-shadow: 0px 0px 4px 3px #1f1e1e;
        border-radius: 10px;
        object-fit: cover;
    }
    &-description{
        width: 55%;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;    
        h1{
            margin-top: 0;
        }
        &-text{
            display: flex;
            flex-direction: column;
        }
        &-video{
            width: 100%;
            height: 60%;
        }
    }


    &-available{
        display: flex;
        flex-direction: column;
        &-cinemas{
            height: 10%;
        }
        &-cinemas > img{
            height: 55px;
            margin-right: 2%;
        }
    }

}
tr{
    height: 65px;
}
.calendar-wrapper{
    width: 100%;
    background-color: black;
}
.event-container{
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    background-color: $third-color;
    width: 100%;
    height: 100%;
    .cineLogoCalendar{
        width: 80%;
    }
    .text-wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        color: $secondary-color;
        width: 100%;
    }

}
.fc-today-button{
    display: none !important;
}
tr {
    //display: none;
    background-color: black;
}
.fc-toolbar-title {
    font-size: 1.2em !important;
}
.fc-timegrid-body,
.fc-scroller > table,
.fc-timegrid-slots > table{
    width: 100% !important;
}

.modalBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 30vh;
    background-color: $primary-color;
    border: 1px solid $secondary-color;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 1px $secondary-color;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: flex-start;
    color: $secondary-color;
}
@media only screen and (min-width: 1400px) {

    .poster{
        width: 30% !important;
    }
    .intro{
        justify-content: space-evenly;
    }
}
@media only screen and (min-width: 975px) {
    .main-container-filter-complex{
        width: 25%;
    }
}

@media only screen and (max-width: 700px) {
    .left-column,
    .right-column{
        width: 100%;
    }
    .intro{
        width: 100%;
        .poster{
            width: 95%;
        }
        &-description{
            width: 95%;
            height: auto;
            &-video{
                height: 400px;
            }
        }
    }
    .fc-view-harness-active{
        height: 70vh !important;
    }
    .modalBox{
        width: 80%;
    }
    .row{
        &-cinemaslogos{
            margin-top: 5%;
        }
    }
}

.fc-timegrid-event-harness{
    width: 95px !important;
}