@import '../../styles/general/variables.scss';
.main-container-filter {
    //position: fixed !important;
    //box-shadow: 0px 0px 11px 3px black;
    //background-color: $primary-color;
    flex-direction: row !important;
    justify-content: space-around !important;
    width: 98%;
    color: $secondary-color;
    z-index: 1;
    label{
        color: $secondary-color;
    }
    &-format{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    &-language{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    &-cinema {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 15%;
        .logo {
            width: 20%;
            border-radius: 10%;
            cursor: pointer;
            &.selected{
                box-shadow: 0px 0px 2px 2px #a3a3a3;
            }
        }
    }
    &-complex{
        position: relative;
        width: 65%;
        display: flex;
        justify-content: center;
        height: 7vh;
        .MuiOutlinedInput-root{
            width: 100%;
            height: 100%;
            svg{
                color: white;
            }
            fieldset{
                border-color: white;
            }
        }
    }
    &-date{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        &-label{
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        &-inputs{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
        }
    }
}

.MuiSvgIcon-root{
    color: white;
}
.fc-toolbar{
    justify-content: space-around !important;
}

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}
.swing-out-top-bck {
	-webkit-animation: swing-out-top-bck 0.45s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
	        animation: swing-out-top-bck 0.45s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}
@media only screen and (min-width: 1000px){
    .main-container-filter > * {
        //width: 25%;
        justify-content: space-around;
    }
    .main-container-filter {
        padding: 1% !important;
        &-cinema {
            width: 15% !important;
        }
    }
}
@media only screen and (max-width: 975px) {
    .main-container-filter {
        flex-direction: column !important;
        align-items: center !important;
        height: 40vh;
        width: 100% !important;
        &-cinema {
            width: 45% !important;
        }
    }
}
@media only screen and (max-width: 600px) {
    .main-container-filter {
        &-cinema {
            width: 75% !important;
        }
    }
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  /**
 * ----------------------------------------
 * animation swing-out-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-top-bck {
    0% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
    100% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
  }
  @keyframes swing-out-top-bck {
    0% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
    100% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
  }
  