@import '../general/variables.scss';
.prices{
    &-main{
        color: $secondary-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        h1{
            font-size: 50px;
        }
        &-accordion{
            width: 90vw;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: space-around;
            justify-content: center;
            align-items: flex-start;
            &-wrapper{
                width: 400px;
                background-color: #000000;
                border: 1px solid white;
                border-radius: 10px;
                padding: 1%;
                margin: 1%;
                &-title{
                    color: $secondary-color;
                }
                &-cinema{
                    align-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    height: 10vh;
                    justify-content: flex-start;
                    img{
                        width: 60px;
                        height: 60px;
                        margin-right: 2%;
                        border-radius: 10px;
                    }
                    p{
                        color: $secondary-color;
                        font-size: 30px;
                        font-weight: 200;
                    }
                }
            }
        }
    }

    &-column-accordion{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }
    &-row-accordion{
        color: $secondary-color;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        align-content: center;
        border: 1px solid gray;
        border-top-width: 0px;
        &-title{
            width: 65%;
        }
        &-price{
            width: 15%;
        }
    }
}

