@import '../general/variables.scss';
#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: $primary-color;
    z-index:9999;
}
circle{
    color: white;
}
