@import '../general/variables.scss';

.info{
    h1{
        color: white;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    &-warn{
        margin: 0 6% 0 6%;
        padding: 2%;
        background-color: wheat;
        border: 2px solid black;
    }
    &-personal{
        margin-top: 5%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        &-desc{
            color: white;
            width: 65vw;
        }
        img{
            width: 20vw;
        }
    }
}
@media only screen and (max-width: 600px) {
    .info{
        &-personal{
            img{
                width: 45vw;
            }
            &-desc{
                width: 90%;
            }
        }
    }
}