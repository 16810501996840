@import '../general/variables.scss';
.main-text{
    color: $secondary-color;
    text-align: center;
    font-size: 2rem;
    margin: 1% auto; 

}

@media only screen and (max-width: 1000px){
    .main-text{
        margin: 5%;
    }
}

